<template>
  <div>
    <base-header type="primary" class="py-5">
      <div class="row align-items-center" id="filters-row">
        <div class="col-lg-3">
          <multiple-select
            multiple
            label="Ports"
            placeholder="Select the ports"
            :options="portos || []"
            optionsValue="name"
            v-model="search.porto_id"
          />
        </div>

        <div class="col-lg-3">
          <multiple-select
            multiple
            label="Comms"
            placeholder="Select the comms"
            :options="cargas || []"
            optionsValue="name"
            v-model="search.carga_tipo_id"
          />
        </div>
        <div class="col-lg-3">
          <multiple-select
            multiple
            label="Charterers"
            placeholder="Select the charterers"
            :options="afretadores || []"
            optionsValue="name"
            v-model="search.afretador_id"
          />
        </div>
        <div class="col-lg-3">
          <multiple-select
            multiple
            label="Disport"
            placeholder="Select the destination port"
            :options="lineupPortos || []"
            optionsValue="port_name"
            v-model="search.destination_port"
          />
        </div>
        <div class="col-lg-3">
          <base-input
            type="date"
            alternative=""
            label="Start period"
            input-classes="form-control-alternative"
            v-model.lazy="search.periodo_inicio"
          />
        </div>
        <div class="col-lg-3">
          <base-input
            type="date"
            alternative=""
            label="End period"
            input-classes="form-control-alternative"
            v-model.lazy="search.periodo_fim"
          />
        </div>
        <div class="col-lg-3" v-if="!$root.isClient">
          <multiple-select
            multiple
            label="Naabsa vessels"
            placeholder="Select naabsa vessels"
            optionsKey="name"
            :options="clientTypes()"
            v-model="search.client_type"
          />
        </div>

        <div class="col-lg-3">
          <div class="form-group has-label float-right w-100">
            <label
              class="form-control-label"
              style="color: transparent"
            ></label>
            <button
              type="submit"
              class="btn base-button btn-success w-100 mt-4"
              @click="listar()"
            >
              <i class="fa fa-search"></i> search
            </button>
          </div>
        </div>
      </div>
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt-3">
      <div class="row g-3">
        <div class="col-12 col-md-3">
          <!-- Card stats -->
          <div class="row">
            <div class="col-12 mb-3">
              <stats-card
                title="Vessels"
                type="red"
                :sub-title="`${qty_vessels}`"
                :icon="{
                  'fas fa-ship': mounted,
                  'fa fa-spinner fa-spin': !mounted,
                }"
                class="mb-4 mb-xl-0"
              >
                <template slot="footer">
                  <span class="text-nowrap">In this period</span>
                </template>
              </stats-card>
            </div>
            <div class="col-12 mb-3">
              <stats-card
                title="Quantity in tons"
                type="red"
                :sub-title="qty_toneladas ? qty_toneladas + 't' : ''"
                :icon="{
                  'fas fa-chart-bar': mounted,
                  'fa fa-spinner fa-spin': !mounted,
                }"
                class="mb-4 mb-xl-0"
              >
                <template slot="footer">
                  <span class="text-nowrap">In this period</span>
                </template>
              </stats-card>
            </div>
            <div class="col-12 mb-3">
              <stats-card
                title="Waiting Days"
                type="red"
                :sub-title="qty_waiting_days + ' days'"
                :icon="{
                  'fas fa-calendar-alt': mounted,
                  'fa fa-spinner fa-spin': !mounted,
                }"
                class="mb-4 mb-xl-0"
              >
                <template slot="footer">
                  <span class="text-nowrap">In this period</span>
                </template>
              </stats-card>
            </div>
          </div>
        </div>
        <div class="col-9">
          <card header-classes="bg-primary" class="mb-4 border shadow-sm">
            <div slot="header" class="row align-items-center">
              <div class="col text-center">
                <h5 class="h3 mb-0 text-white">
                  <i class="fa fa-spinner fa-spin" v-if="!mounted"></i> Share
                  per Charterer
                </h5>
              </div>
            </div>
            <div class="row justify-content-between">
              <div class="col-12 col-md-4">
                <table class="table" v-if="mounted">
                  <thead>
                    <tr class="border-dark">
                      <td><b>CHARTER</b></td>
                      <td><b>VESSELS</b></td>
                    </tr>
                  </thead>
                  <tr
                    v-for="(afretador, index) in afretadoresChart.chartData
                      .labels"
                  >
                    <td>
                      {{ afretador }}
                    </td>
                    <td>
                      {{ getChartDataByIndex(afretadoresChart, index) }}
                    </td>
                  </tr>
                </table>
              </div>
              <div class="col-12 col-md-7" style="position: relative">
                <small
                  :style="`position: absolute;
                    top: 30px;
                    right: 115px;
                    font-weight: bold;
                    z-index: 99;
                    text-transform: uppercase;
                    ${mounted ? '' : 'display:none'}
                    `"
                  >Charterers</small
                >
                <google-chart
                  v-if="mounted"
                  type="PieChart"
                  :data="afretadoresChart.chartData"
                  :extra-options="{
                    width: 425,
                  }"
                  :handle-label="
                    (label, data) => {
                      return `${label}: ${data} VESSEL${data > 1 ? 'S' : ''}`;
                    }
                  "
                />
              </div>
            </div>
          </card>
        </div>
        <div class="col-12">
          <card header-classes="bg-primary" class="mb-4 border shadow-sm">
            <div slot="header" class="row align-items-center">
              <div class="col text-center">
                <h5 class="h3 mb-0 text-white">
                  <i class="fa fa-spinner fa-spin" v-if="!mounted"></i>Share per
                  destination
                </h5>
              </div>
            </div>
            <div class="row justify-content-end">
              <div class="col-12 col-md-6" style="position: relative">
                <small
                  :style="`position: absolute;
                    bottom: 30px;
                    left: 90px;
                    font-weight: bold;
                    z-index: 99;
                    text-transform: uppercase;
                    ${mounted ? '' : 'display:none'}
                    `"
                  >Vessels</small
                >
                <google-chart
                  v-if="mounted"
                  type="GeoChart"
                  :data="portosChart.chartData"
                  :extra-options="{
                    is3D: true,
                    colorAxis: { colors: ['#C8E6C9', '#4CD78B'] },
                  }"
                />
              </div>
              <div class="col-12 col-md-6" style="position: relative">
                <small
                  :style="`position: absolute;
                    top: 30px;
                    right: 115px;
                    font-weight: bold;
                    z-index: 99;
                    text-transform: uppercase;
                    ${mounted ? '' : 'display:none'}
                    `"
                  >Destination</small
                >
                <google-chart
                  v-if="mounted"
                  type="PieChart"
                  :data="portosChart.chartData"
                  :extra-options="{ pieHole: 0.6 }"
                  :handle-label="
                    (label, data) => {
                      return `${label}: ${data} VESSEL${data > 1 ? 'S' : ''}`;
                    }
                  "
                  :colors="portosChart.chartData.datasets[0].backgroundColor"
                />
              </div>
            </div>
          </card>
        </div>
        <div class="col-xl-12">
          <card header-classes="bg-primary" class="mb-4 border shadow-sm">
            <div slot="header" class="row align-items-center">
              <div class="col text-center">
                <h5 class="h3 mb-0 text-white">
                  <i class="fa fa-spinner fa-spin" v-if="!mounted"></i>
                  Commodities and Quantities
                </h5>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6" style="position: relative">
                <small
                  :style="`position: absolute;
                    top: 30px;
                    right: 150px;
                    font-weight: bold;
                    z-index: 99;
                    text-transform: uppercase;
                    ${mounted ? '' : 'display:none'}
                    `"
                  >Comms</small
                >
                <google-chart
                  v-if="mounted"
                  type="PieChart"
                  :data="cargasChart.chartData"
                  :extra-options="{ pieHole: 0.6 }"
                  :handle-label="
                    (label, data) => {
                      return `${label}: ${formatNumber(data)} t`;
                    }
                  "
                />
              </div>
              <div class="col-12 col-md-6 p-0">
                <vertical-bar-chart
                  :chart="cargasChart.chartData"
                  class
                  v-if="mounted"
                />
              </div>
            </div>
          </card>
        </div>
      </div>
      <!-- End charts-->
      <span
        >For more information, click
        <a
          href="http://comexstat.mdic.gov.br/en/comex-vis"
          style="color: blue; text-decoration: underline"
          >here</a
        >
        for Brazilian Government Statistics.</span
      >
    </div>
  </div>
</template>
<script>
import * as chartConfigs from "@/components/Charts/config";
import LineChart from "@/components/Charts/LineChart";
import BarChart from "@/components/Charts/BarChart";
import PieChart from "@/components/Charts/PieChart";
import SocialTrafficTable from "./Dashboard/SocialTrafficTable";
import PageVisitsTable from "./Dashboard/PageVisitsTable";
import GoogleChart from "../components/Dashboard/GoogleChart.vue";
import VerticalBarChart from "../components/Dashboard/VerticalBarChart.vue";
import Service from "@/services/Dashboard";
import "../assets/js/google-chart";

export default {
  components: {
    LineChart,
    BarChart,
    PieChart,
    PageVisitsTable,
    SocialTrafficTable,
    GoogleChart,
    VerticalBarChart,
  },
  data() {
    return {
      mounted: false,
      qty_vessels: "",
      qty_toneladas: "",
      qty_waiting_days: "",
      portos: [],
      cargas: [],
      navios: [],
      afretadores: [],
      lineupPortos: [],
      search: {
        carga_tipo_id: [],
        porto_id: [],
        afretador_id: [],
        destination_port: [],
        periodo_inicio: null,
        periodo_fim: null,
        navio_id: [],
        client_type: [],
      },
      cargasChart: {
        chartData: {
          datasets: [
            {
              data: [],
              backgroundColor: [
                "#073763",
                "#1D4971",
                "#345B7F",
                "#4A6D8D",
                "#607F9B",
                "#7791A9",
                "#8DA3B7",
                "#A3B5C5",
                "#BAC7D3",
                "#D0D9E1",
              ],
            },
          ],
          labels: [],
        },
        extraOptions: chartConfigs.getPieOptions(70),
      },
      portosChart: {
        chartData: {
          datasets: [
            {
              data: [],
              backgroundColor: [
                "#4cd78b",
                "#69db99",
                "#7cdda2",
                "#74dc9e",
                "#87dea8",
                "#8ddfab",
                "#99e0b1",
                "#a5e2b7",
                "#afe3bc",
                "#bae4c2",
                "#c8e6c9",
                // "#073763",
                // "#1D4971",
                // "#345B7F",
                // "#4A6D8D",
                // "#607F9B",
                // "#7791A9",
                // "#8DA3B7",
                // "#A3B5C5",
                // "#BAC7D3",
                // "#D0D9E1",
              ],
            },
          ],
          labels: [],
        },
        extraOptions: chartConfigs.getPieOptions(70),
      },
      afretadoresChart: {
        chartData: {
          datasets: [
            {
              data: [],
              backgroundColor: [
                "#073763",
                "#1D4971",
                "#345B7F",
                "#4A6D8D",
                "#607F9B",
                "#7791A9",
                "#8DA3B7",
                "#A3B5C5",
                "#BAC7D3",
                "#D0D9E1",
              ],
            },
          ],
          labels: [],
        },
        extraOptions: chartConfigs.pieChartOptions,
      },
    };
  },
  methods: {
    formatNumber(number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    getRandomColor() {
      const color = Math.floor(Math.random() * 16777215).toString(16);
      return "#" + color;
    },
    async listar() {
      this.mounted = false;
      const {
        qty_toneladas,
        qty_vessels,
        qty_waiting_days,
        afretadores,
        cargas,
        portos,
      } = (await Service.get(this.search)).data;
      this.qty_waiting_days = qty_waiting_days;
      this.qty_toneladas = qty_toneladas;
      this.qty_vessels = qty_vessels;
      this.setChart(afretadores, "afretadoresChart", "lineups_count", "name");
      this.setChart(cargas, "cargasChart", "lineups_sum_qty");
      this.setChart(portos, "portosChart", "lineups_count", "pais");
      this.mounted = true;
    },
    setChart(values, chart, key, name = "name") {
      this[chart].chartData.labels = [];
      this[chart].chartData.datasets[0].data = [];
      for (let i = 0; i < values.length; i++) {
        this[chart].chartData.labels[i] = values[i][name];
        this[chart].chartData.datasets[0].data[i] = values[i][key];
      }
    },
    getChartDataByIndex(chart, index) {
      return chart.chartData.datasets[0].data[index];
    },
    getChartBackGroundColorByIndex(chart, index) {
      return chart.chartData.datasets[0].backgroundColor[index];
    },
    async setFields() {
      const { cargas, portos, lineupPortos, afretadores, navios } = (
        await Service.fields()
      ).data;
      this.cargas = cargas;
      this.portos = portos;
      this.lineupPortos = lineupPortos;
      this.afretadores = afretadores;
      this.navios = navios;
      this.listar();
    },
    clientTypes() {
      return Service.clientTypes();
    },
  },
  mounted() {
    this.setFields();
  },
};
</script>
<style>
#filters-row label {
  color: white !important;
}
.ball {
  border-radius: 50%;
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 4px;
}
.table td {
  padding: 5px 2px;
}
</style>
