import Abstract from "./Abstract";
import LineupService from "./Lineups";
export const base_url = "/dashboard";
export const url = process.env.VUE_APP_API_BASE_URL + base_url;
export const abstract = new Abstract(url);
export default class Dashboard {
  static get(params) {
    return abstract.get({ ...params });
  }

  static fields() {
    return new Abstract(url + "/fields").get();
  }

  static clientTypes() {
    return LineupService.clientTypes();
  }
}
