<template>
  <div ref="chart" class="chart"></div>
</template>

<script>
export default {
  name: "google-chart",
  props: {
    type: String,
    data: Object,
    extraOptions: {
      type: Object,
      default: () => ({}),
    },
    handleLabel: {
      type: Function,
      default: null,
    },
    colors: {
      type: Array,
      default: () => [
        "#67799E",
        "#758BAE",
        "#899CBA",
        "#98ABC4",
        "#A5B8CB",
        "#B3C6D3",
        "#BFD1DB",
        "#C6D8E1",
        "#CFE0E7",
        "#D6E8EE",
      ],
    },
  },
  data: () => ({
    slices: {
      0: { textStyle: { color: "white", fontSize: 11 } },
      1: { textStyle: { color: "white", fontSize: 11 } },
      2: { textStyle: { color: "white", fontSize: 11 } },
      3: { textStyle: { color: "white", fontSize: 11 } },
      4: { textStyle: { color: "white", fontSize: 11 } },
      5: { textStyle: { color: "white", fontSize: 11 } },
      6: { textStyle: { color: "black", fontSize: 11 } },
      7: { textStyle: { color: "black", fontSize: 11 } },
      8: { textStyle: { color: "black", fontSize: 11 } },
      9: { textStyle: { color: "black", fontSize: 11 } },
    },
  }),
  mounted() {
    google.charts.load("current", { packages: ["corechart", "geochart"] });
    google.charts.setOnLoadCallback(this.drawChart);
  },
  methods: {
    drawChart() {
      const { data } = this.data.datasets[0];
      const list = [["", "QTTY"]];
      if (this.handleLabel) {
        list[0].push({ type: "string", role: "tooltip" });
      }
      for (let i = 0; i < data.length; i++) {
        const label = this.data.labels[i] || "OTHERS";
        const value = Number(data[i]);
        const element = [label, value];
        if (this.handleLabel) element.push(this.handleLabel(label, value));
        list.push(element);
      }

      const values = google.visualization.arrayToDataTable(list);
      const options = {
        ...this.extraOptions,
        hAxis: { title: "Year", titleTextStyle: { color: "#333" } },
        vAxis: { minValue: 0 },
        legend: this.extraOptions.legend || {
          alignment: "center",
          position: "right",
          textStyle: {
            fontSize: 14,
            margin: 0,
          },
        },
        title:'teste',
        slices: this.slices,
        chartArea: {
          left: 0,
          top: 0,
          width: "550%",
          height: "550%",
        },
        colors: this.colors,
        tooltip: {
          trigger: "hover",
        },
      };
      const chart = new google.visualization[this.type](this.$refs.chart);
      chart.draw(values, options);
    },
  },
};
</script>
<style></style>
